<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lý bài học / bài kiểm tra
      </h1>
    </div>
    <b-card no-body>
      <b-tabs pills card v-model="currentStep" v-if="!is_call_api">
        <b-tab active title-link-class="btn btn-light-info">
          <template v-slot:title>
            <v-icon>mdi-format-list-bulleted</v-icon>
            <p class="mb-0">Danh sách bài học / bài kiểm tra</p>
          </template>
          <TestList :is_list_draft="currentStep"></TestList>
        </b-tab>
        <b-tab title-link-class="btn btn-light-info">
          <template v-slot:title>
            <v-icon>mdi-file</v-icon>
            <p class="mb-0">Các bài nháp / đang soạn</p>
          </template>
          <TestList :is_list_draft="currentStep"></TestList>
        </b-tab>
      </b-tabs>
    </b-card>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";

export default {
  name: "TabTestList",
  components: {
    Loading: () => import("vue-loading-overlay"),
    TestList: () => import("./TestList.vue"),
  },
  data() {
    return {
      is_call_api: false,
      currentStep: 0,
    };
  },
  created() {},
  methods: {
    getAllTest(page = 1, query = "") {
      let seft = this;
      ApiService.get("prep-app/test?page=" + page + "&name=" + query).then(
        function (response) {
          if (response.status == 200) {
            seft.data_test = response.data;
          }
        }
      );
    },
    getAllTestDraft(page = 1, query = "") {
      let seft = this;
      ApiService.get(
        "prep-app/test/draft/list?page=" + page + "&name=" + query
      ).then(function (response) {
        if (response.status == 200) {
          seft.data_test_draft = response.data;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.nav.nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #6993ff !important;
}
.text-tab {
  color: #ffffff;
  background-color: #6993ff !important;
}
</style>
